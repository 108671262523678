import './header.scss'

const Header = () => {
    return (
        <div className="header">
            <div className="text-back-drop">
                <p>Isaac James</p>
            </div>
        </div>
    )
}

export default Header